/* Buttons */

a, a:active, a:hover {
    outline: 0;
}

.button-container {
    display: inline-block;
    height: 7rem;
    width: 7rem;
    margin: 0 1.75rem;
}

.button {
    transition: color 0.5s linear;
    height: 7rem;
    width: 7rem;
    color: var(--primaryTextColor);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    position: relative;
    z-index: 1;
    border-radius: 25%;
}

.icon {
    height: 5rem;
    width: 5rem;
    padding: 1rem;
}

.icon_title {
    font-size: 1.75rem;
}

.button:hover {
    background-color: var(--shadowColor);
    box-shadow: 0 0 0.75rem 0.75rem rgba(128, 128, 128, 0.2);
}

.button:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.button-container .icon_title {
    display: none;
}

.button-container:hover .icon_title {
    display: initial;
}

.button-container:hover .icon {
    display: none;
}

/* Mobile Devices */

@media only screen and (max-device-width: 820px) and (-webkit-min-device-pixel-ratio: 2) {
    .button-container {
        height: 5rem;
        width: 5rem;
        margin: 0 0.8rem;
    }

    .button {
        height: 5rem;
        width: 5rem;
    }

    .icon {
        height: 4rem;
        width: 4rem;
        padding: 0.5rem;
    }

    .icon_title {
        font-size: 1.3rem;
    }
}
